<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <el-row :gutter="0">
      <el-col :span="20" :offset="2">
        <header class="page-header">
          <el-row :gutter="20" align="middle">
            <el-col :span="12">
              <h2>Бараа буцаалтын жагсаалт</h2>
            </el-col>
            <!-- <el-col :span="12">
              <div class="actions text-right">
                <router-link to="/add-return">
                  <el-button type="success" class="item">Нэмэх</el-button>
                </router-link>
              </div>
            </el-col> -->
          </el-row>
        </header>
        <!-- Tabled content -->
        <div class="panel tabled">
          <el-tabs v-model="activeTabStatus" @tab-click="onPressTab">
            <el-tab-pane label="All" name="all">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer"
                        ></el-button>
                      </el-input>
                    </el-col>
                  </el-row>
                </div>
                <return-table :returns="returns" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></return-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Pending" name="pending">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown>
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            >Product title A - Z</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Producvt title Z - A</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created newest first</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created oldest first</el-dropdown-item
                          >
                          <el-dropdown-item>Low inventory</el-dropdown-item>
                          <el-dropdown-item>High inventory</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <return-table :returns="returns" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></return-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Partial" name="partial">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown>
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            >Product title A - Z</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Producvt title Z - A</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created newest first</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created oldest first</el-dropdown-item
                          >
                          <el-dropdown-item>Low inventory</el-dropdown-item>
                          <el-dropdown-item>High inventory</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <return-table :returns="returns" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></return-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Completed" name="complete">
              <div class="panel-content">
                <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="20">
                      <el-input placeholder="Хайх" class="input-with-select" v-model="searchText" @keyup.enter.native="searchTransfer">
                        <el-button
                          slot="prepend"
                          icon="el-icon-search"
                          @click="searchTransfer"
                        ></el-button>
                      </el-input>
                    </el-col>
                    <el-col :span="4" :offset="4" class="text-right">
                      <el-dropdown>
                        <el-button type="default" class="lowercase"
                          >Эрэмбэлэх <i class="el-icon-sort"></i
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            >Product title A - Z</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Producvt title Z - A</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created newest first</el-dropdown-item
                          >
                          <el-dropdown-item
                            >Created oldest first</el-dropdown-item
                          >
                          <el-dropdown-item>Low inventory</el-dropdown-item>
                          <el-dropdown-item>High inventory</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-col>
                  </el-row>
                </div>
                <return-table :returns="returns" :isLoading="isLoading" :pageSize="pageSize" :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></return-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// // @ is an alias to /src
import services from '../../../helpers/services'
import ReturnTable from './components/returnTable'
import { getSupplierId } from '../../../utils/auth'
export default {
  name: 'returnList',
  components: {
    ReturnTable
  },
  data () {
    return {
      totalCount: 0,
      currentPage: 1,
      pageSize: 20,
      search: '',
      searchText: '',
      status: 'all',
      tableData: null,
      returns: [],
      isLoading: false,
      activeTabStatus: 'all',
      statusFilter: '',
      statusFilterOptions: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'draft',
          label: 'Draft'
        },
        {
          value: 'archived',
          label: 'Archived'
        }
      ],
      options: [
        {
          value: 'Нэр А - Я',
          label: 'Нэр А - Я'
        },
        {
          value: 'Нэр Я - А',
          label: 'Нэр Я - А'
        },
        {
          value: 'Бүртгэгдсэн огноо буурах',
          label: 'Бүртгэгдсэн огноо буурах'
        },
        {
          value: 'Бүртгэгдсэн огноо өсөх',
          label: 'Бүртгэгдсэн огноо өсөх'
        },
        {
          value: 'Агуулахад цөөн',
          label: 'Агуулахад цөөн'
        },
        {
          value: 'Агуулахад их',
          label: 'Агуулахад их'
        }
      ]
    }
  },
  async mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getReturnsBySupplierId(await getSupplierId(), size, from)
  },
  methods: {
    async onPressTab (data) {
      switch (data.label) {
        case 'All':
          this.status = ''
          break
        case 'Pending':
          this.status = 'pending'
          break
        case 'Partial':
          this.status = 'partial'
          break
        case 'Completed':
          this.status = 'complete'
          break
        default:
          this.status = ''
      }
      this.getReturnsBySupplierId(await getSupplierId(), this.pageSize, (this.pageSize * (this.currentPage - 1)), this.status)
    },
    async sizeChangeHandler (size) {
      this.pageSize = size
      this.$router.push({ name: 'return', query: { page: this.currentPage, size: size } })
      this.getReturnsBySupplierId(await getSupplierId(), this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    async curentPageHandler (current) {
      this.currentPage = current
      this.$router.push({ name: 'return', query: { page: current, size: this.pageSize } })
      this.getReturnsBySupplierId(await getSupplierId(), this.pageSize, (this.pageSize * (this.currentPage - 1)))
    },

    async getReturnsBySupplierId (supplierIds, size, from, status) {
      this.loading = true
      const body = {
        supplier_ids: supplierIds,
        size: size,
        from: from,
        return_status: status
      }
      services.getReturnsBySupplierId(body).then(response => {
        this.loading = false
        this.returns = response.data
        this.totalCount = response.total
      })
    },

    searchTransfer () {
      this.getReturns()
    }
  }
}
</script>
<style>
.el-table .cell {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
